<template>
  <section>
    <h3 class="text-h4">
      {{ $t("yourFooCoins") }}:
      <span class="primary--text">{{ userCoins }}</span>
    </h3>
  </section>
</template>

<script>
export default {
  name: "InfoUserCoinsComponent",
  props: {
    userCoins: { type: Number, required: true },
  },
};
</script>
